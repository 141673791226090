import React, { useState } from "react"
import { FaFax, FaMapMarked, FaMapMarkedAlt, FaPhoneAlt } from "react-icons/fa"
import MapModal from "../MapModal"
import PropTypes from "prop-types"

const LocationItem = ({
  location: { locationName, address, phone, fax, image, id },
  setCurrentLocationId,
}) => {
  return (
    <div className="location-single flex flex-wrap mb-12 sm:w-1/2 sm:pr-6 lg:mb-20">
      <div
        className="location-image cursor-pointer overflow-hidden relative flex w-32 h-32 rounded lg:rounded-lg lg:w-56 lg:h-56"
        onClick={() => {
          setCurrentLocationId(id)
        }}
        role={"button"}
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <img
          src={image}
          alt={locationName}
          className="max-h-full w-full object-cover"
        />
        <div className="absolute top-0 left-0 w-full h-full z-10 transition-all duration-300 ease-out opacity-0 flex items-center justify-center hover:opacity-100">
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50" />
          <FaMapMarked className="text-6xl text-white relative z-20" />
        </div>
      </div>
      <div className="location-meta pl-4 flex-1 lg:pl-6">
        <h3 className="title-border-sm text-xl font-serif leading-normal tracking-wide text-gray-700 lg:text-4xl">
          {locationName}
        </h3>
        <ul className="mt-4">
          <li className="text-sm text-gray-500 flex mb-2 lg:text-base">
            <i className="text-primary inline-block mr-3 relative pt-1">
              <FaPhoneAlt />
            </i>
            {phone}
          </li>
          <li className="text-sm text-gray-500 flex mb-2 lg:text-base">
            <i className="text-primary inline-block mr-3 relative pt-1">
              <FaFax />
            </i>
            {fax}
          </li>
          <li className="text-sm text-gray-500 flex mb-2 lg:text-base">
            <i className="text-primary inline-block mr-3 relative pt-1">
              <FaMapMarkedAlt />
            </i>
            {address}
          </li>
        </ul>
      </div>
    </div>
  )
}

const LocationList = ({ locations }) => {
  const [currentLocationId, setCurrentLocationId] = useState(null)
  const locationList = locations.map((location, index) => (
    <LocationItem
      key={index}
      location={location}
      setCurrentLocationId={setCurrentLocationId}
    />
  ))
  return (
    <>
      <div className="location-list -mb-12 sm:flex sm:flex-wrap">
        {locationList}
      </div>
      <MapModal
        locations={locations}
        setCurrentLocationId={setCurrentLocationId}
        currentLocationId={currentLocationId}
      />
    </>
  )
}

LocationItem.propTypes = {
  location: PropTypes.object,
  setCurrentLocationId: PropTypes.func,
}
LocationList.propTypes = {
  locations: PropTypes.array,
}

export default LocationList
