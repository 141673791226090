import React, { useEffect, useRef, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ContextConsumer from "../../Context"
import usePost from "../hooks/usePost"
import useLandingImage from "../hooks/useLandingImage"
import useStat from "../hooks/useStat"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/index/Services"
import MediaSlider from "../components/index/MediaSlider"
import ClientSlider from "../components/index/ClientSlider"
import LocationList from "../components/index/LocationList"
import LineButton from "../components/LineButton"
import PrimaryBtn from "../components/PrimaryButton"
import Brand from "../components/index/Brand"
import Stat from "../components/index/Stat"
import LawyerList from "../components/index/LawyerList"
import useLocationLawyerData from "../hooks/useLocationLawyerData"
import useLocationData from "../hooks/useLocationData"

const Home = () => {
  const { lawyers } = useLocationLawyerData()
  const { locations } = useLocationData()
  const { team: teamImage } = useLandingImage()
  const { medias, services, clients, brands } = usePost()
  const stats = useStat()

  const [activeSection, setActiveSection] = useState(null)
  const brandSectionRef = useRef(null)
  const contactSectionRef = useRef(null)

  let setNavActive = () => {}
  useEffect(() => {
    const handleScroll = () => {
      let scrollTop = window.pageYOffset
      const offset = 100
      let active = null
      if (contactSectionRef.current && brandSectionRef.current) {
        const contactSectionTop = contactSectionRef.current.offsetTop
        const brandSectionTop = brandSectionRef.current.offsetTop
        if (scrollTop + offset > contactSectionTop) {
          active = "contact"
        } else if (scrollTop + offset > brandSectionTop) {
          active = "brand"
        }
        setActiveSection(active)
        setNavActive(active)
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [activeSection])

  return (
    <Layout transParentMenu={true}>
      <ContextConsumer>
        {({ setAnchorLinkActive }) => {
          setNavActive = setAnchorLinkActive
        }}
      </ContextConsumer>

      <div className="index-page">
        <Hero />
        <MediaSlider sliders={medias} />

        <section>
          <div className="container mt-6 mx-auto sm:flex">
            <div className="sm:w-1/4 text-center sm:text-left">
              <h2 className="title-border">服務項目</h2>
            </div>
            <div className="mt-8 sm:mt-0 sm:w-3/4">
              <Services services={services} />
            </div>
          </div>
        </section>

        <section className="bg-gray-200 pt-0">
          <div className="bg-primary">
            <div className="container text-center relative pt-16 pb-56">
              <div className="number-bg-fixer absolute top-0 bottom-0 bg-gray-200 w-screen hidden -mr-6 lg:block" />
              <div className="sm:flex sm:justify-around">
                <Stat stats={stats} lawyerLength={lawyers.length} />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="sm:flex sm:items-end">
              <div className="team-image -mt-32 sm:order-2 sm:w-1/2 sm:pl-12 lg:flex lg:-mb-48">
                <GatsbyImage
                  className="w-full"
                  image={teamImage}
                  alt="喆律團隊"
                />
              </div>
              <div className="text-gray-500 pt-12 sm:pt-0 sm:order-1 sm:w-1/2">
                <div className="text-center sm:text-left">
                  <h2 className="title-border">喆律團隊</h2>
                </div>
                <p className="mt-8 leading-normal lg:text-xl">
                  喆律法律事務所無論在規模、服務據點及法律服務專業領域上皆快速成長，對客戶的服務品質也不斷自我要求建立業界標竿；本所律師團隊皆具深厚法學素養及實務經驗，且秉持用法律來服務人群的志願，協助當事人在優質的服務體驗下取得最佳利益。
                </p>
                <p className="mt-4 text-sm lg:text-base leading-loose">
                  <LawyerList lawyers={lawyers} />
                </p>
                <div className="text-center mt-6 sm:text-left">
                  <LineButton>
                    <PrimaryBtn>免費法律諮詢</PrimaryBtn>
                  </LineButton>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="h-24 hidden lg:block" />
          <div className="container sm:flex">
            <div className="text-center sm:order-2 sm:w-1/4 sm:text-right">
              <h2 className="title-border">本所服務客戶</h2>
            </div>
            <div className="mt-12 sm:mt-0 sm:order-1 sm:w-3/4">
              <ClientSlider sliders={clients} />
            </div>
          </div>
        </section>

        <section id="brand" ref={brandSectionRef} className="bg-gray-200">
          <div className="container">
            <div className="text-center">
              <h2 className="title-border">喆律品牌</h2>
            </div>
            <p className="mt-8 leading-normal text-gray-500 lg:text-xl">
              喆律法律事務導入科學辦案、專案管理系統及數位化行銷等方式，結合律師的法律專業及實務經驗，推出各項專業法律領域品牌，除了從數據分析提供律師訴訟策略外，更透過服務流程優化帶給民眾更值得相信及更佳服務體驗的法律品牌。
            </p>
            <div className="brand-list sm:flex sm:flex-wrap">
              <Brand brands={brands} />
            </div>
          </div>
        </section>

        <section id="contact" ref={contactSectionRef} className="relative">
          <div className="container">
            <div className="text-center">
              <h2 className="title-border">聯絡我們</h2>
              <p className="text-left text-gray-500 mt-8 leading-normal lg:text-xl">
                喆律法律事務所在台北、桃園、新竹、台中、高雄都設有辦公室，友善舒服的諮詢環境及服務流程皆保障高品質的法律服務，有任何法律問題歡迎聯絡我們，喆律法律事務所將竭誠為您服務！
              </p>
              <div className="text-center mt-6 mb-12">
                <LineButton>
                  <PrimaryBtn>免費法律諮詢</PrimaryBtn>
                </LineButton>
              </div>
            </div>
            <LocationList locations={locations} />
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default Home
