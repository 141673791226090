// import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const useStat = () => {
  const data = useStaticQuery(graphql`
    {
      stats: allContentfulStats {
        nodes {
          title
          number
        }
      }
    }
  `)
  return data.stats.nodes
}

export default useStat
