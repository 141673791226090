import { useStaticQuery, graphql } from "gatsby"

const usePost = () => {
  const data = useStaticQuery(graphql`
    {
      posts: allContentfulPost(sort: { order: ASC, fields: order }) {
        nodes {
          id
          description {
            description
          }
          image {
            id
            file {
              url
            }
          }
          title
          url
          type
          order
        }
      }
    }
  `)

  let posts = data.posts

  return {
    posts: posts.nodes,
    medias: posts.nodes.filter(post => post.type === "media"),
    services: posts.nodes.filter(post => post.type === "service"),
    clients: posts.nodes.filter(post => post.type === "client"),
    brands: posts.nodes.filter(post => post.type === "brand"),
  }
}

export default usePost
