import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FaArrowRight, FaArrowLeft } from "react-icons/fa"
import PropTypes from "prop-types"

function NextArrow(props) {
  return (
    <div
      className="w-12 h-12 max-w-xs bg-gray-200 text-xl text-gray-500 flex items-center justify-center cursor-pointer rounded-full absolute bottom-0 mb-6 z-10 focus:outline-none"
      style={{ right: "12px", bottom: "-24px" }}
      onClick={props.onClick}
      role={"button"}
      tabIndex={0}
      onKeyDown={() => {}}
    >
      <FaArrowRight>Next</FaArrowRight>
    </div>
  )
}

NextArrow.propTypes = {
  onClick: PropTypes.func,
}

function PrevArrow(props) {
  return (
    <div
      className="w-12 h-12 max-w-xs bg-gray-200 text-xl text-gray-500 flex items-center justify-center cursor-pointer rounded-full absolute bottom-0 mb-6 z-10 focus:outline-none"
      style={{ right: "76px", bottom: "-24px" }}
      onClick={props.onClick}
      role={"button"}
      tabIndex={0}
      onKeyDown={() => {}}
    >
      <FaArrowLeft>Prev</FaArrowLeft>
    </div>
  )
}

PrevArrow.propTypes = {
  onClick: PropTypes.func,
}

export default function ClientSlider({ sliders }) {
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    pauseOnHover: true,
    cssEase: "ease-in-out",
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: "150px",
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 3,
    className: "pb-12",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          rows: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 3,
        },
      },
    ],
  }
  return (
    <div className="relative">
      <Slider {...settings}>
        {sliders.map(client => {
          return (
            <div
              key={client.id}
              className="w-1/2 px-3 mb-6 sm:w-1/3 lg:w-1/4 lg:mb-12"
            >
              <div className="client-image mx-auto h-24 w-24 flex items-center ">
                <img src={client.image.file.url} alt={client.title} />
              </div>

              <p className="leading-normal text-center text-gray-500 h-14 mt-3">
                {client.title}
              </p>
            </div>
          )
        })}
      </Slider>
      <div className="h-12" />
    </div>
  )
}

ClientSlider.propTypes = {
  sliders: PropTypes.arrayOf(PropTypes.object),
}
