import React, { useRef, useEffect } from "react"
import useOutsideClick from "../hooks/useOutsideClick"
import { FaTimesCircle } from "react-icons/fa"
import PropTypes from "prop-types"

const MapItem = ({
  location: { locationName, id },
  isCurrentLocation,
  setCurrentLocationId,
}) => {
  return (
    <li
      className={`h-10 items-center cursor-pointer focus:outline-none ${
        isCurrentLocation ? "border-b border-primary" : null
      }`}
    >
      <div
        onClick={async () => await setCurrentLocationId(id)}
        role={"button"}
        tabIndex={0}
      >
        <h4 className="font-serif leading-loose font-heavy text-gray-700 text-center focus:outline-none">
          {locationName}
        </h4>
      </div>
    </li>
  )
}

const MapModal = ({ locations, currentLocationId, setCurrentLocationId }) => {
  const modalRef = useRef(null)
  const contentRef = useRef(null)

  const getMapById = currentLocationId => {
    const target = locations[currentLocationId]
    if (target) {
      return `<iframe
        src="${target.url}"
        width="600" height="450" frameBorder="0" style="border:0;" allowFullScreen="" aria-hidden="false"
        tabIndex="0"/>`
    } else {
      return null
    }
  }
  const mapCode = getMapById(currentLocationId)

  useOutsideClick(contentRef, setCurrentLocationId, null)

  useEffect(() => {
    if (modalRef.current) {
      const modal = modalRef.current
      modal.classList.add("opacity-100")
    }
  })

  if (!mapCode) {
    return null
  }

  const mapList = locations.map((location, index) => (
    <MapItem
      key={index}
      location={location}
      isCurrentLocation={location.id === currentLocationId}
      setCurrentLocationId={setCurrentLocationId}
    />
  ))

  return (
    <div
      ref={modalRef}
      className="fixed top-0 left-0 w-full h-full z-50 px-6 py-20 transition-all duration-500 ease-in-out opacity-0 sm:px-8 sm:flex sm:items-center sm:justify-center"
    >
      <div className="overlay top-0 left-0 opacity-75 bg-black top-0 left-0 w-full h-full" />
      <div className="text-white absolute top-0 right-0 z-10 text-2xl mt-6 mr-6 cursor-pointer sm:mr-8 sm:ml-8">
        <FaTimesCircle />
      </div>
      <div
        className="bg-white rounded-lg w-full h-full relative z-10 p-6 flex flex-col sm:h-128 lg:w-256"
        ref={contentRef}
      >
        <nav>
          <ul className="grid grid-cols-5 border-b border-gray-300">
            {mapList}
            <div className="nav-active-bar" />
          </ul>
        </nav>

        <div
          className={`map flex-grow pt-6 ${
            currentLocationId === null ? "hidden" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: mapCode }}
        />
      </div>
    </div>
  )
}

export default MapModal

MapItem.propTypes = {
  location: PropTypes.object,
  isCurrentLocation: PropTypes.bool,
  setCurrentLocationId: PropTypes.func,
}

MapModal.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
  currentLocationId: PropTypes.number,
  setCurrentLocationId: PropTypes.func,
}
