import React, { useState } from "react"
import PropTypes from "prop-types"

const ServiceItem = ({
  service: {
    title,
    image: {
      file: { url },
    },
    description: { description },
  },
  setCurrent,
  isCurrent,
  index,
}) => {
  return (
    <div
      onMouseOver={() => {
        setCurrent(index)
      }}
      onMouseOut={() => {
        setCurrent(null)
      }}
      onFocus={() => void 0}
      onBlur={() => void 0}
      className="service-list-single px-8 pb-1 mb-6 relative cursor-pointer h-40 flex items-center focus:outline-none"
      role={"button"}
      tabIndex={0}
      onClick={() => {
        setCurrent(index)
      }}
      onKeyDown={() => {}}
    >
      <div className="mx-auto d-flex items-center justify-center">
        <div className="mx-auto mobile:w-24">
          <img src={url} alt={title} className="service-image block mx-auto" />
        </div>
        <h3 className="text-base leading-normal font-medium text-gray-700 border-b border-primary mx-auto table sm:font-base lg:text-xl">
          {title}
        </h3>
        <div
          className={`opacity-0 absolute top-0 left-0 right-0 bottom-0 z-20 bg-gray-200 p-3 flex items-center justify-center transition-all duration-150 ease-linear ${
            isCurrent ? "opacity-100" : null
          }`}
        >
          <div>
            <h3 className="text-base leading-normal font-medium text-gray-700 border-b border-primary mx-auto table sm:font-base lg:text-xl">
              {title}
            </h3>
            <p className="text-sm leading-normal text-gray-500 mt-3 lg:text-base">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const Services = ({ services }) => {
  const [current, setCurrent] = useState(null)
  const serviceList = services.map((service, index) => (
    <ServiceItem
      key={index}
      service={service}
      isCurrent={current === index}
      setCurrent={setCurrent}
      index={index}
    />
  ))

  return <div className="grid grid-cols-2 sm:grid-cols-3">{serviceList}</div>
}

export default Services

ServiceItem.propTypes = {
  service: PropTypes.object,
  isCurrent: PropTypes.bool,
  setCurrent: PropTypes.func,
  index: PropTypes.number,
}
Services.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
}
