import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const twoWordAddSpace = name => {
  if (name.length === 2) {
    return name[0] + "　" + name[1]
  }
  return name
}

const Lawyer = ({ lawyer: { slug, name }, isLast }) => {
  return (
    <span>
      <Link to={`/lawyer/${slug}`} className="inline-block hover:text-gray-600">
        {twoWordAddSpace(name)}
      </Link>
      <span className={`${isLast ? "hidden" : ""}`}>
        &nbsp;&nbsp;|&nbsp;&nbsp;
      </span>
    </span>
  )
}
const LawyerList = ({ lawyers }) => {
  const lawyerList = lawyers.map((lawyer, index) => (
    <Lawyer key={index} lawyer={lawyer} isLast={index === lawyers.length - 1} />
  ))

  return <>{lawyerList}</>
}

Lawyer.propTypes = {
  lawyer: PropTypes.object,
  isLast: PropTypes.bool,
}

LawyerList.propTypes = {
  lawyers: PropTypes.array,
}

export default LawyerList
