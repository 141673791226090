import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FaArrowRight, FaArrowLeft } from "react-icons/fa"
import PropTypes from "prop-types"

function NextArrow(props) {
  return (
    <div
      className="w-12 h-12 bg-white text-xl text-gray-500 flex items-center justify-center cursor-pointer rounded-full shadow-md absolute right-0 top-0 focus:outline-none"
      role="button"
      tabIndex={0}
      onClick={props.onClick}
      onKeyDown={() => {}}
    >
      <FaArrowRight>Next</FaArrowRight>
    </div>
  )
}

NextArrow.propTypes = {
  onClick: PropTypes.func,
}

function PrevArrow(props) {
  return (
    <div
      className="w-12 h-12 bg-white text-xl text-gray-500 flex items-center justify-center cursor-pointer rounded-full shadow-md absolute z-10 left-0 top-0 focus:outline-none"
      role="button"
      tabIndex={0}
      onClick={props.onClick}
      onKeyDown={() => {}}
    >
      <FaArrowLeft>Prev</FaArrowLeft>
    </div>
  )
}

PrevArrow.propTypes = {
  onClick: PropTypes.func,
}

export default function MediaSlider({ sliders }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 9,
    initialSlide: 0,
    pauseOnHover: true,
    cssEase: "ease-in-out",
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: "50px",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          centerMode: true,
          arrows: false,
        },
      },
      {
        breakpoint: 639,
        settings: {
          autoplay: false,
          cssEase: "linear",
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          arrows: false,
        },
      },
    ],
  }

  return (
    <div className="media-slider bg-gray-200 py-6 sm:py-12 ">
      <div className="lg:container mx-auto">
        <Slider {...settings}>
          {sliders.map((slider, index) => (
            <div className="px-4" key={index}>
              <Link to="/media">
                <img
                  className="media-single-img"
                  src={slider.image.file.url}
                  alt={slider.title}
                />
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

MediaSlider.propTypes = {
  sliders: PropTypes.arrayOf(PropTypes.object),
}
