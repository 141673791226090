import { graphql, useStaticQuery } from "gatsby"
import useLocationData from "./useLocationData"
import default_profile from "../images/default_profile.png"

const useLocationLawyerData = () => {
  const { locations } = useLocationData()

  const data = useStaticQuery(graphql`
    {
      allAirtableLawyer(sort: { fields: rowIndex, order: ASC }) {
        nodes {
          data {
            Name
            Experience
            Education
            Slug
            Profile {
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 550)
                }
              }
            }
            Position {
              data {
                Name
              }
            }
            Office {
              data {
                Name
              }
            }
            News
            Practice_Area
            License_Number
          }
        }
      }
    }
  `)

  const lawyers = data.allAirtableLawyer.nodes.map(node => {
    const {
      data: {
        Name,
        Experience,
        Education,
        Profile,
        Position,
        Office,
        Slug,
        News,
        Practice_Area,
        License_Number,
      },
    } = node
    return {
      name: Name,
      experience: Experience || "",
      education: Education || "",
      profile:
        Profile.localFiles[0].childImageSharp.gatsbyImageData ||
        default_profile,
      position: Position[0].data.Name,
      office: Office[0].data.Name,
      slug: Slug,
      news: News,
      practiceArea: Practice_Area,
      licenseNumber: License_Number,
    }
  })

  // 依照各分所去區分律師
  const locationPeople = []
  locations.forEach(location => {
    const list = []
    lawyers.forEach(person => {
      if (person.office === location.locationName) {
        list.push(person)
      }
    })
    const obj = {
      locationName: location.locationName,
      address: location.address,
      phone: location.phone,
      lawyers: list,
    }
    locationPeople.push(obj)
  })

  return { locationPeople, lawyers }
}

export default useLocationLawyerData
