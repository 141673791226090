import React from "react"
import PropTypes from "prop-types"

const BrandItem = ({
  brand: {
    url,
    image: { file },
    title,
    description: { description },
  },
}) => {
  return (
    <div className="brand-single relative mt-6 border-b border-gray-300 last:border-b-0 sm:border-b-0 sm:w-1/2 sm:px-6 lg:w-1/4 lg:border-r">
      <div className="flex w-16 h-16 absolute inset-0 sm:w-24 sm:h-24 sm:relative sm:mx-auto">
        <a href={url} target="_blank" rel="noreferrer">
          <img
            src={file.url}
            className="cursor-pointer object-contain"
            alt={title}
          />
        </a>
      </div>
      <div className="pl-20 pb-6 sm:pl-0">
        <a href={url} target="_blank" rel="noreferrer">
          <h4 className="cursor-pointer transition-all duration-300 ease-out text-xl leading-normal font-medium text-gray-700 border-b border-primary table sm:table sm:mx-auto sm:mt-3">
            {title}
          </h4>
        </a>
        <p className="mt-3 leading-normal text-gray-500">{description}</p>
      </div>
    </div>
  )
}

const Brand = ({ brands }) => {
  const brandList = brands?.map((brand, index) => (
    <BrandItem brand={brand} key={index} />
  ))

  return <>{brandList}</>
}

BrandItem.propTypes = {
  brand: PropTypes.object,
}

Brand.propTypes = {
  brands: PropTypes.array,
}

export default Brand
