import React from "react"
import PropTypes from "prop-types"
import TrackVisibility from "react-on-screen"
import CountUp from "react-countup"

const StatItem = ({ stat: { title, number }, isLawyerStat }) => {
  return (
    <div className="mt-12 sm:mt-0">
      <p className="text-6xl font-serif leading-normal text-center text-white border-b-2 border-white table mx-auto lg:text-7xl lg:border-b-4">
        <TrackVisibility tag={"span"} once={true}>
          {({ isVisible }) =>
            isVisible ? <CountUp start={0} end={number} duration={2} /> : "0"
          }
        </TrackVisibility>
        <span className={`${isLawyerStat ? "hidden" : ""}`}>+</span>
      </p>
      <h4 className="text-xl font-body mt-3 leading-normal text-center font-medium text-white lg:text-2xl">
        {title}
      </h4>
    </div>
  )
}

const Stat = ({ stats, lawyerLength }) => {
  const statItems = [{ title: "專業律師", number: lawyerLength }]
    .concat(stats)
    .map((stat, index) => (
      <StatItem key={index} stat={stat} isLawyerStat={index === 0} />
    ))

  return <>{statItems}</>
}

StatItem.propTypes = {
  stat: PropTypes.object,
  isLawyerStat: PropTypes.bool,
}
Stat.propTypes = {
  stats: PropTypes.array,
  lawyerLength: PropTypes.number,
}

export default Stat
